import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import PopUp from 'react/shared/components/popups/PopUp';
import StateDropdown from 'react/shared/components/state_dropdown';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function UpdateCardholderAddressModal({ accountId, toggleModal }) {
  const onSubmit = async () => {
    const form = $('#change-cardholder-address-form');
    if (!form.valid()) {
      Truelink.flash(
        'error',
        "You haven't provided all the information we need yet. Please provide the missing information.",
      );
      return;
    }

    try {
      const route = RailsRoutes.dashboard_create_address_change_request_path(accountId);
      await $.ajax({ url: route, type: 'POST', data: $(form).serializeArray() });

      Truelink.flash('success', 'Your address change has been submitted.');
      toggleModal();
    } catch {
      Truelink.flash('error', 'Failed to submit your address change. Try again.');
    }
  };

  return (
    <PopUp
      bodyHeight="48vh"
      footer={
        <TrueLinkButton onClick={onSubmit} variant="primary">
          Save
        </TrueLinkButton>
      }
      header="Change Cardholder's Address"
      id="change_address_modal"
      maxHeight="650px"
      maxWidth="560px"
      onClose={toggleModal}
      openModal
    >
      <form id="change-cardholder-address-form">
        <label htmlFor="address_street1">
          <p>Street</p>
          <input id="address-street1" name="address[street1]" required type="text" />
        </label>
        <label htmlFor="address_street2">
          <p>Apt or unit number</p>
          <input id="address-street2" name="address[street2]" type="text" />
        </label>
        <label htmlFor="address_city">
          <p>City</p>
          <input id="address-city" name="address[city]" required type="text" />
        </label>
        <label htmlFor="address_state">
          <p>State</p>
          <StateDropdown id="address-state" name="address[state]" required />
        </label>
        <label htmlFor="address_zip">
          <p>Zip</p>
          <ReactInputMask id="address-zip" mask="99999" name="address[zip]" required type="text" />
        </label>
        <p>Please allow 2-4 business days to update the cardholder's address.</p>
        <p>We'll follow up with you about any questions that come up as we process this request.</p>
      </form>
    </PopUp>
  );
}

UpdateCardholderAddressModal.propTypes = {
  accountId: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
