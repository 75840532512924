import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export default function MarketoHiddenForm({ formId }) {
  const marketoFormId = formId || 1362;
  useEffect(() => {
    try {
      const marketoAppId = '117-GMI-496';
      window.MktoForms2.loadForm('//info.truelinkfinancial.com', marketoAppId, marketoFormId);
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      // do nothing since this is external code from marketing and if it fails, we have no control
    }
  }, [marketoFormId]);

  return <form id={`mktoForm_${marketoFormId}`} style={{ display: 'none' }} />;
}

MarketoHiddenForm.propTypes = {
  formId: PropTypes.number,
};
