import Cookies from 'js-cookie';

export default function trackUserData(params) {
  try {
    if ($tlf.environment === 'production') {
      const myForm = window.MktoForms2.allForms()[0];
      myForm.addHiddenFields(params);

      const utmVars = Cookies.get('utm_vars') ? JSON.parse(Cookies.get('utm_vars')) : {};
      const utmParams = {};

      for (const [key, value] of Object.entries(utmVars)) {
        utmParams[`${key}__c`] = value;
      }
      myForm.addHiddenFields(utmParams);

      myForm.submit();
    }
    // eslint-disable-next-line no-unused-vars
  } catch (e) {
    // do nothing since this is external code from marketing and if it fails, we have no control
  }
}
