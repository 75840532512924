import PropTypes from 'prop-types';
import React from 'react';
import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';
import BeneficiarySignupStore from 'react/member/stores/beneficiary_dashboard/beneficiary_signup_store';
import bindAll from 'react/shared/utils/bind_all';

export default class BeneficiarySignupPersonal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: props.firstName,
      lastName: props.lastName,
      mobile: props.mobile,
      alternatePhone: props.alternatePhone,
      masterServicesAgreement: false,
      authorizedByCardholder: false,
      started: props.started,
      submitting: false,
      completed: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    BeneficiarySignupStore.on('beneficiarySignups.personDetails.create', this.personDetailsCreated);
  }

  componentDidUpdate(prevProps) {
    if (this.props.started !== prevProps.started) {
      // This is legacy behavior and would require refactoring the state structure to fix. New behavior should not thrash the render cycle this way.

      this.setState({ started: this.props.started });
    }
  }

  componentWillUnmount() {
    BeneficiarySignupStore.off(
      'beneficiarySignups.personDetails.create',
      this.personDetailsCreated,
    );
  }

  updateField(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  updateCheck(e) {
    const state = this.state;
    state[e.target.name] = !!e.target.checked;
    this.setState(state);
  }

  submitPersonalDetails() {
    const { resourceSlug, tbis } = this.props;
    BeneficiaryDashboardActions.createSignupPersonDetails({
      data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobile: this.state.mobile,
        alternatePhone: this.state.alternatePhone,
      },
      resourceSlug,
      tbis,
    });
  }

  personDetailsCreated() {
    this.props.completePersonal();
  }

  render() {
    const { firstName, lastName, mobile, alternatePhone } = this.state;
    const divClass = this.props.started ? 'card card--form' : 'card card--form section-unstarted';
    return (
      <div className={divClass} id="edit-administrator-section">
        <div className="card--form__left">
          <div className="card--form__left__header">2. About you</div>
          <div className="card--form__left__body hide-on-complete">
            Please provide your name and phone number(s). We will never share or sell your phone
            number (or any other information)
          </div>
        </div>
        <div className="card--form__right">
          <form
            action="/404.html"
            className="card--form__right__body hide-on-complete clearfix"
            id="edit-administrator-form"
          >
            <div className="card--form__section">
              <div className="card--form__row">
                <div className="card--form__col">
                  <label htmlFor="firstName">
                    First name
                    <input
                      id="firstName"
                      name="firstName"
                      onChange={this.updateField}
                      required
                      type="text"
                      value={firstName}
                    />
                  </label>
                </div>
                <div className="card--form__col">
                  <label htmlFor="lastName">
                    Last name
                    <input
                      id="lastName"
                      name="lastName"
                      onChange={this.updateField}
                      required
                      type="text"
                      value={lastName}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="card--form__section">
              <div className="card--form__row">
                <div className="card--form__col">
                  <label htmlFor="mobile">
                    Mobile phone number
                    <span className="card--form__input-addition">Optional</span>
                    <input
                      id="mobile"
                      name="mobile"
                      onChange={this.updateField}
                      type="text"
                      value={mobile}
                    />
                  </label>
                </div>
                <div className="card--form__col">
                  <label htmlFor="alternatePhone">
                    Home phone number
                    <span className="card--form__input-addition">Optional</span>
                    <input
                      id="alternatePhone"
                      name="alternatePhone"
                      onChange={this.updateField}
                      type="text"
                      value={alternatePhone}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="card--form__right__footer">
              <input
                className="btn-new btn-new--green btn-new--large"
                onClick={this.submitPersonalDetails}
                type="button"
                value="Continue"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

BeneficiarySignupPersonal.propTypes = {
  tbis: PropTypes.string.isRequired,
  resourceSlug: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  mobile: PropTypes.string,
  alternatePhone: PropTypes.string,
  started: PropTypes.bool.isRequired,
  completePersonal: PropTypes.func.isRequired,
};
