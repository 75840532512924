import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AttachmentDropZone from 'react/member/components/attachments/drop_zone_uploader/AttachmentDropZone';
import api from 'react/member/components/dashboard/deposits/individual_trusts/api';
import { fetchAttachments } from 'react/member/components/dashboard/disbursements/filter_table/utils/attachments_api';
import AttachmentsTable from 'react/shared/components/AttachmentsTable';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { asMoney } from 'react/shared/utils/Money';
import ErrorText from 'react/signups/card/common/ErrorText';

export default function WealthAccountEventModal({
  amount,
  memo,
  id,
  onSuccess,
  onCancel,
  beneficiarySlug,
  refetchEvents,
}) {
  const [isUploading, setIsUploading] = useState(false);

  const validationSchema = Yup.object().shape({
    memo: Yup.string().nullable().max(255, 'Description must be 255 characters or less'),
  });

  const initialValues = {
    memo: memo || '',
  };

  const onBeginUpload = () => {
    setIsUploading(true);
  };

  const fetchAttachmentsForEvent = () =>
    fetchAttachments({ attachable: { id, type: 'WealthAccountEvent' } });

  const onUploadSuccess = () => {
    setIsUploading(false);
    fetchAttachmentsForEvent();
    refetchEvents();
  };

  const onSubmit = async (values) => {
    try {
      await api.putWealthAccountEvent(beneficiarySlug, { id, memo: values.memo });
      Truelink.flash('success', 'Deposit description successfully updated!');
      onSuccess();
    } catch {
      Truelink.flash('error', 'There was an error trying to update the deposit description');
    }
  };

  return (
    <TrueLinkModal
      handleClose={onCancel}
      iconCloseBtn
      maxWidth="sm"
      modalProps={{ fullWidth: true }}
      title="Edit Deposit Record"
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, setFieldTouched, setFieldValue, values, isSubmitting, isValid }) => (
          <>
            <Box alignItems="center" display="flex" mb={2}>
              <Box flexBasis="33.33%" pr={2}>
                <Typography variant="h5">Amount:</Typography>
              </Box>
              <Box flex={1}>
                <Typography>{asMoney(amount)}</Typography>
              </Box>
            </Box>
            <Box alignItems="center" display="flex" mb={3}>
              <Box flexBasis="33.33%" pr={2}>
                <Typography variant="h5">Description:</Typography>
              </Box>
              <Box flex={1}>
                <TextField
                  fullWidth
                  id="memo"
                  minRows={2}
                  multiline
                  name="memo"
                  onBlur={(_e) => setFieldTouched('memo', true)}
                  onChange={(e) => setFieldValue('memo', e.target.value, true)}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      boxShadow: 'none',
                    },
                  }}
                  value={values.memo}
                />
                <ErrorText name="memo" />
              </Box>
            </Box>
            <AttachmentsTable
              fetchAttachments={fetchAttachmentsForEvent}
              onDeleteAttachment={refetchEvents}
            />
            {isUploading && (
              <LoadingIndicator
                containerStyle={{
                  display: 'flex',
                  margin: 8,
                  justifyContent: 'center',
                }}
              />
            )}
            <AttachmentDropZone
              attachable={{ id: String(id), type: 'WealthAccountEvent' }}
              hideToast
              onBeginUpload={onBeginUpload}
              onSuccess={onUploadSuccess}
            />
            <Box mt={2}>
              <TrueLinkButton
                disabled={!isValid || isSubmitting}
                onClick={handleSubmit}
                size="large"
                type="submit"
                variant="primary"
              >
                Save
              </TrueLinkButton>
            </Box>
          </>
        )}
      </Formik>
    </TrueLinkModal>
  );
}

WealthAccountEventModal.propTypes = {
  amount: PropTypes.string.isRequired,
  memo: PropTypes.string,
  id: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  beneficiarySlug: PropTypes.string.isRequired,
  refetchEvents: PropTypes.func.isRequired,
};
