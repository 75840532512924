/* eslint-disable react/prop-types */

import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  dateRangeChip: {
    fontWeight: 100,
    color: PALETTE.emerald,
  },
}));

export default function DateRangeFilter({ column: { filterValue = [], setFilter } }) {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const handleOpenPopper = () => {
    setOpen((prev) => !prev);
    setPlacement('bottom');
  };
  const showDateRangeChips = filterValue[0] !== undefined || filterValue[1] !== undefined;
  const [startDate, setStartDate] = useState(filterValue[0] || new Date());
  const [endDate, setEndDate] = useState(filterValue[1] || new Date());

  const handleDateApply = () => {
    setFilter((old = []) => [startDate ? startDate : undefined, old[1]]);
    setFilter((old = []) => [old[0], endDate ? endDate : undefined]);
    setOpen(false);
  };

  const handleClearDates = () => {
    setFilter();
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const chipDateValue = () => {
    const formattedStartDate =
      filterValue[0] && new Date(filterValue[0]).toLocaleDateString('en-US');
    const formattedEndDate = filterValue[1] && new Date(filterValue[1]).toLocaleDateString('en-US');

    if (formattedStartDate && formattedEndDate) {
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    if (formattedStartDate && !formattedEndDate) {
      return formattedStartDate;
    }
    if (!formattedStartDate && formattedEndDate) {
      return formattedEndDate;
    }
  };

  const chipLabel = chipDateValue();
  const handleClickAway = () => {
    handleClearDates();
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <>
      <div id="date-range-popper" ref={containerRef}>
        {showDateRangeChips && !open ? (
          <Paper
            component={'ul'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyle: 'none',
              marginRight: '10px',
              marginTop: '9px',
              alignItems: 'center',
            }}
          >
            <ListItem style={{ paddingRight: 0, paddingLeft: 0 }}>
              {(filterValue[0] || filterValue[1]) && (
                <TrueLinkChip
                  className={classes.dateRangeChip}
                  color="primary"
                  label={chipLabel}
                  onDelete={handleClearDates}
                />
              )}
            </ListItem>
            <TrueLinkButton
              onClick={handleOpenPopper}
              size="small"
              style={{ border: 'none', margin: 'auto 0', padding: '7px' }}
              variant="neutralOutline"
            >
              <TrueLinkIcon icon="caret-down" />
            </TrueLinkButton>
          </Paper>
        ) : (
          <TrueLinkButton
            onClick={handleOpenPopper}
            size="small"
            style={{
              height: '42px',
              marginLeft: '5px',
              marginRight: '10px',
              padding: '8px',
            }}
            variant="neutralOutline"
          >
            Date Period
            <TrueLinkIcon icon="caret-down" style={{ marginLeft: '5px' }} />
          </TrueLinkButton>
        )}
      </div>
      <Popper
        anchorEl={containerRef.current}
        open={open}
        placement={placement}
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            style={{
              width: 'auto',
              height: '150px',
              padding: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <DatePicker
                onChange={(date) => {
                  setStartDate(date);
                }}
                selected={filterValue[0] ? new Date(filterValue[0]) : startDate}
                wrapperClassName="date_range_filter_datepicker"
              />
              <Typography style={{ marginRight: '5px', marginLeft: '5px' }}>to</Typography>
              <DatePicker
                onChange={(date) => {
                  setEndDate(date);
                }}
                selected={filterValue[1] ? new Date(filterValue[1]) : endDate}
                wrapperClassName="date_range_filter_datepicker"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '25px 10px 10px 10px',
              }}
            >
              <TrueLinkButton onClick={handleDateApply} size="small" variant="primary">
                Apply
              </TrueLinkButton>
              <TrueLinkLink onClick={handleClearDates} size="small" variant="error">
                Clear
              </TrueLinkLink>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
