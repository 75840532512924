import { Formik } from 'formik';
import React from 'react';
import TrueLinkTextInput, { textInputTypes } from './TrueLinkTextInput';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const icons = {
  none: null,
  'cookie-bite': <TrueLinkIcon icon="cookie-bite" />,
  'laugh-wink': <TrueLinkIcon icon="laugh-wink" />,
  'dollar-sign': <TrueLinkIcon icon="dollar-sign" />,
};

export default {
  title: 'Forms/TextInput',
  component: TrueLinkTextInput,
  argTypes: {
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
    endAdornment: {
      control: { type: 'select' },
      mapping: icons,
      options: ['cookie-bite', 'laugh-wink', 'none'],
      table: { category: 'Style' },
    },
    labelText: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    labelAdornment: {
      control: { type: 'select' },
      mapping: icons,
      options: ['cookie-bite', 'laugh-wink', 'none'],
      table: { category: 'Style' },
    },
    startAdornment: {
      control: { type: 'select' },
      mapping: icons,
      options: ['cookie-bite', 'laugh-wink', 'dollar-sign', 'none'],
      table: { category: 'Style' },
    },
    multiline: {
      control: 'boolean',
      table: {
        category: 'Contents',
      },
    },
    rows: {
      control: 'number',
      table: {
        category: 'Contents',
      },
    },
    name: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    onChange: {
      action: 'changed',
      table: {
        category: 'Events',
      },
    },
    placeholder: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    required: {
      control: 'Boolean',
      table: {
        category: 'Contents',
      },
    },
    setFieldTouched: {
      action: 'touched',
      table: {
        category: 'Events',
      },
    },
    type: {
      options: textInputTypes,
      control: { type: 'select' },
      table: {
        category: 'Contents',
      },
    },
    value: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  return (
    <Formik>
      <TrueLinkTextInput {...args} key={Math.random()} />
    </Formik>
  );
}

export const Unlabeled = Template.bind({});
Unlabeled.args = {
  required: false,
  name: 'lastName',
  placeholder: 'Last Name',
};

export const Labeled = Template.bind({});
Labeled.args = {
  required: false,
  name: 'firstName',
  labelText: 'What is your name?',
  placeholder: 'Last, First',
};

export const LabelAdornment = Template.bind({});
LabelAdornment.args = {
  required: false,
  name: 'dessert',
  labelAdornment: 'cookie-bite',
  labelText: 'What is your favorite cookie?',
  placeholder: 'Chocolate Chip, Molasses, Peanut Butter...',
};

export const Password = Template.bind({});
Password.args = {
  type: 'password',
  required: false,
  name: 'pass',
  placeholder: 'Secrets go here',
};

export const EndAdornment = Template.bind({});
EndAdornment.args = {
  required: true,
  name: 'adorn',
  placeholder: '',
  endAdornment: 'laugh-wink',
};

export const StartAdornment = Template.bind({});
StartAdornment.args = {
  required: true,
  name: 'startAdorn',
  placeholder: '1000',
  startAdornment: 'dollar-sign',
};

export const MultiLine = Template.bind({});
MultiLine.args = {
  required: true,
  name: 'multiLine',
  value:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ' +
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ' +
    'exercitation',
  rows: 4,
  multiline: true,
};
