import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class UpdateNicknameModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardholderNickname: props.cardholderNickname,
      newCardholderNickname: '',
    };

    bindAll(this);
  }

  onFieldChange(e) {
    this.setState({
      newCardholderNickname: e.target.value,
    });
  }

  async onSubmit() {
    const form = $('#update-account-id-form');
    if (!form.valid()) {
      Truelink.flash(
        'error',
        "You haven't provided all the information we need yet. Please provide the missing information.",
      );
      return;
    }

    try {
      const route = RailsRoutes.dashboard_update_cardholder_path(this.props.accountId, {
        format: 'json',
      });
      await $.ajax({ url: route, type: 'PUT', data: $(form).serializeArray() });

      Truelink.flash('success', 'Your account id has been saved.');
      this.props.setNickname(this.state.newCardholderNickname);
      this.props.toggleModal();
    } catch {
      Truelink.flash('error', 'Failed to submit your account id change. Please try again.');
    }
  }

  renderForm() {
    return (
      <form className="new-form new-form--compact" id="update-account-id-form">
        <div className="new-form-field new-form-field--medium">
          <div className="new-form__label">Name:</div>
          <div className="new-form__data">{this.props.cardholderName}</div>
        </div>
        <div className="new-form-field new-form-field--medium">
          <div className="new-form__label">Current Account ID:</div>
          <div className="new-form__data nickname">{this.state.cardholderNickname}</div>
        </div>
        <div className="form-break" />
        <div className="new-form-field new-form-field--medium">
          <div className="new-form__label">New Account ID:</div>
          <div className="new-form__data">
            <input
              name="update_account_id"
              onChange={this.onFieldChange}
              required
              type="text"
              value={this.state.newCardholderNickname}
            />
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <PopUp
        bodyHeight="48vh"
        footer={
          <TrueLinkButton onClick={this.onSubmit} variant="primary">
            Save
          </TrueLinkButton>
        }
        header="Edit Account ID"
        id="update_account_id_modal"
        maxHeight="550px"
        maxWidth="560px"
        onClose={this.props.toggleModal}
        openModal
      >
        {this.renderForm()}
      </PopUp>
    );
  }
}

UpdateNicknameModal.propTypes = {
  accountId: PropTypes.string.isRequired,
  cardholderName: PropTypes.string,
  cardholderNickname: PropTypes.string,
  setNickname: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
