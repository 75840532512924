/* eslint-disable react/prop-types */

import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: PALETTE.red,
    margin: `${theme.spacing(2)} auto`,
  },
  inputDiv: {
    // This is written to target the div plus the input because other CSS was overriding
    // this when targeting the input directly
    '& input': {
      padding: theme.spacing(2),
      margin: 0,
      height: 'auto',
      '&::placeholder': {
        fontStyle: 'normal',
      },
    },
  },
  listContainer: {
    maxHeight: 175,
    overflow: 'auto',
  },
}));

export default function CategoryFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [placement, setPlacement] = useState(null);
  const [categories, setCategories] = useState([]);
  const containerRef = useRef(null);
  const showCategoryChips = categories.length > 0;
  const [foundCategories, setFoundCategories] = useState([]);

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    const result = [...options.values()].filter((option) => option !== null);
    setFoundCategories(result);
    return result;
  }, [id, preFilteredRows]);

  useEffect(() => {
    if (filterValue === undefined) {
      setCategories([]);
    }
  }, [filterValue]);
  const handleOpenPopper = () => {
    setOpen((prev) => !prev);
    setPlacement('bottom');
  };

  const handleCategoryApply = () => {
    setFilter(categories && categories.length ? categories : undefined);
    setOpen(false);
  };

  const handleClearCategories = () => {
    setFilter();
    setName('');
    setCategories([]);
    setFoundCategories(options);
  };

  const handleCategoryDelete = (selectedCategory) => {
    setCategories(categories.filter((ele) => ele != selectedCategory));
    setFilter(categories.filter((ele) => ele != selectedCategory));
  };

  const handleToggle = (value) => () => {
    const currentIndex = categories.indexOf(value);
    const newChecked = [...categories];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCategories(newChecked);
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = options.filter((option) =>
        option.toLowerCase().startsWith(keyword.toLowerCase()),
      );
      setFoundCategories(results);
    } else {
      setFoundCategories(options);
    }

    setName(keyword);
  };

  const handleClickAway = () => {
    handleClearCategories();
    setOpen(false);
  };

  return (
    <>
      <div id="category-popper-container" ref={containerRef}>
        {showCategoryChips && !open ? (
          <Paper
            component={'ul'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyle: 'none',
              marginRight: '10px',
              marginTop: '9px',
              marginLeft: '10px',
              alignItems: 'center',
              height: '44px',
            }}
          >
            <ListItem style={{ paddingRight: 0 }}>
              {categories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  onDelete={() => handleCategoryDelete(category)}
                  style={{
                    color: PALETTE.emerald,
                    borderRadius: '6px',
                    backgroundColor: PALETTE.emeraldLight,
                  }}
                />
              ))}
            </ListItem>
            <TrueLinkButton
              onClick={handleOpenPopper}
              size="small"
              style={{ border: 'none', margin: 'auto 0', padding: '7px' }}
              variant="neutralOutline"
            >
              <TrueLinkIcon icon="caret-down" />
            </TrueLinkButton>
          </Paper>
        ) : (
          <TrueLinkButton
            onClick={handleOpenPopper}
            size="small"
            style={{
              height: '42px',
              marginLeft: '5px',
              marginRight: '10px',
              padding: '8px',
            }}
            variant="neutralOutline"
          >
            Category
            <TrueLinkIcon icon="caret-down" style={{ marginLeft: '5px' }} />
          </TrueLinkButton>
        )}
      </div>
      <Popper
        anchorEl={containerRef.current}
        open={open}
        placement={placement}
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            style={{
              width: 'auto',
              height: 'auto',
              padding: '15px',
            }}
          >
            <TextField
              className={classes.inputDiv}
              onChange={filter}
              placeholder="Category"
              value={name}
            />
            <List className={classes.listContainer}>
              {foundCategories.length > 0 ? (
                foundCategories.sort().map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem dense key={value} onClick={handleToggle(value)} role={undefined}>
                      <ListItemIcon>
                        <Checkbox
                          checked={categories.includes(value)}
                          disableRipple
                          edge="start"
                          inputProps={{ 'aria-labelledby': labelId }}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value} />
                    </ListItem>
                  );
                })
              ) : (
                <Typography>No results</Typography>
              )}
            </List>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <TrueLinkButton onClick={handleCategoryApply} size="small" variant="primary">
                Apply
              </TrueLinkButton>
              <TrueLinkLink onClick={handleClearCategories} size="small" variant="error">
                Clear
              </TrueLinkLink>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
