import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import useDisbursementShowQuery from 'react/member/components/dashboard/clients/hooks/useDisbursementShowQuery';
import useTrustBeneficiaryShowQuery from 'react/member/components/dashboard/clients/hooks/useTrustBeneficiaryShowQuery';
import DetailBalanceInfo from 'react/member/components/dashboard/disbursements/DetailBalanceInfo';
import DisbursementDeleteConfirmationModal from 'react/member/components/dashboard/disbursements/DisbursementDeleteConfirmationModal';
import PendingDisbursementDetailActions from 'react/member/components/dashboard/disbursements/disbursement_details/PendingDisbursementDetailActions';
import DisbursementDecisions from 'react/member/components/dashboard/disbursements/filter_table/disbursement_decisions/DisbursementDecisions';
import DisbursementPaymentForm from 'react/member/components/dashboard/disbursements/filter_table/disbursement_payments/DisbursementPaymentForm';
import DisbursementCategoryAmounts from 'react/member/components/dashboard/disbursements/filter_table/disbursements_category_amounts/DisbursementCategoryAmounts';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import { grey4 } from 'react/shared/theme/palette';

export default function PendingDisbursementsDetailView({
  disbursement,
  renderOnHoldDisbursements,
  showSendDisbursementBackToPending,
  userRoles,
}) {
  //TODO this data is fetched from the old v1 endpoint. When the missing fields are available in
  // the v2 api the PendingDisbursementsDetailViewWrapper can be removed.

  const disbursementId = `${disbursement.id}`;
  const cardId = disbursement.card_id;
  const paymentTypes = disbursement.trust_beneficiary?.trust?.displayed_payment_types || [];
  const trustBeneficiarySlug = disbursement.trust_beneficiary?.slug;
  const payeeId = disbursement.payee_id;
  const clientId = disbursement.trust_beneficiary?.organization.slug;
  const clientThreeMonthInfo = disbursement.trust_beneficiary?.three_month_info;
  const clientOneMonthInfo = disbursement.trust_beneficiary?.one_month_info;
  const clientZeroDayInfo = disbursement.trust_beneficiary?.zero_day_info;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [payees, setPayees] = useState([]);
  const [payeesLoaded, setPayeesLoaded] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState(disbursement.payee);

  const onFetchBeneficiaryPayee = (payees) => {
    const payee = payees.find((payee) => payee.id === payeeId);
    if (payee) {
      setSelectedPayee(payee);
    }
    setPayees(payees);
    setPayeesLoaded(true);
  };

  const onAddBeneficiaryPayee = (payee) => {
    setSelectedPayee(payee);
  };

  useEffect(() => {
    DashboardPayeeStore.on('payees.add', onAddBeneficiaryPayee);
    DashboardPayeeStore.on('payees.fetch', onFetchBeneficiaryPayee);

    return () => {
      DashboardPayeeStore.off('payees.add', onAddBeneficiaryPayee);
      DashboardPayeeStore.off('payees.fetch', onFetchBeneficiaryPayee);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: { data, disbursementCategoryAmounts, decisions } = {},
    isLoading: isDisbursementLoading,
  } = useDisbursementShowQuery({
    disbursementId,
  });

  const { data: trustBeneData, isLoading: isBeneficiaryLoading } = useTrustBeneficiaryShowQuery({
    clientSlug: trustBeneficiarySlug,
  });

  const isLoading = isDisbursementLoading || isBeneficiaryLoading;

  const disbursementStatus = data?.attributes?.status;
  const paymentMethod = data?.attributes.paymentMethod;

  const updateDisbursement = (values) => {
    if (!values.payee_id && !['Card', 'Direct Debit'].includes(paymentMethod)) {
      Truelink.flash('error', 'Failed to update disbursement. You must select a payee.');
      return;
    }

    const primaryId = disbursementId;

    const payload = {
      disbursement: {
        payment_method: values.payment_method,
        payee_id: values.payee_id?.id,
        postage_code: values.postage_code,
        check_memo_line: values.check_memo_line,
        card_id: values.card_id,
      },
      disbursements: values.categories,
    };

    DashboardActions.updateDisbursement(primaryId, payload);
    setIsEditing(false);
  };

  const canPrintDisbursement = disbursementStatus !== 'Draft';
  const canDeleteDisbursement = data?.meta.canBeDeleted;

  const canRemoveHold = (status) =>
    status === 'Pending on Hold' ||
    status === 'Proposed on Hold' ||
    status === 'Additional Approval on Hold';

  const canGoOnHold = (status) =>
    status === 'Pending' || status === 'Proposed' || status === 'Additional Approval';

  const removeHoldAttributes = () => {
    let attrs;
    switch (disbursementStatus) {
      case 'Pending on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Pending' }];

        break;
      }
      case 'Additional Approval on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Additional Approval' }];

        break;
      }
      case 'Proposed on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Proposed' }];

        break;
      }
      // No default
    }
    return attrs;
  };

  const sendDisbursementBackToPending = () => {
    DashboardActions.returnDisbursementForRevision(disbursementId);
  };

  const removeHold = () => {
    if (canRemoveHold(disbursementStatus)) {
      const payload = removeHoldAttributes(disbursement);
      DashboardActions.updateDisbursement(disbursementId, payload);
    }
  };

  const putOnHold = () => {
    if (canGoOnHold(disbursementStatus)) {
      const payload = [{ name: 'disbursement[status]', value: `${disbursementStatus} on Hold` }];
      DashboardActions.updateDisbursement(disbursementId, payload);
    }
  };

  const deleteDisbursement = () => {
    DashboardActions.destroyDisbursement(disbursement);
    setShowDeleteModal(false);
  };

  const toggleEdit = () => {
    DashboardActions.fetchPayees(trustBeneficiarySlug);
    setIsEditing(true);
  };

  const detailsViewHeader = (handleSubmit) => (
    <tr
      className="detail-view-row"
      style={{
        height: '50px',
        borderBottom: `1px solid ${grey4}`,
        backgroundColor: 'whitesmoke',
      }}
    >
      <td colSpan="100%">
        <div className="flex flex--space-between">
          <div>
            <Typography fontWeight="500">Disbursement Details</Typography>
          </div>
          {!isLoading && (
            <PendingDisbursementDetailActions
              canDelete={canDeleteDisbursement}
              canPrint={canPrintDisbursement}
              clientId={clientId}
              deletePendingDisbursement={() => setShowDeleteModal(true)}
              disbursement={data}
              handleSubmit={handleSubmit}
              isEditing={isEditing}
              onToggleEdit={toggleEdit}
              putOnHold={putOnHold}
              removeHold={removeHold}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              sendDisbursementBackToPending={sendDisbursementBackToPending}
              showSendDisbursementBackToPending={showSendDisbursementBackToPending}
            />
          )}
        </div>
      </td>
    </tr>
  );

  const loading = (
    <tr>
      <td colSpan={6}>
        <Box display="flex" justifyContent="center">
          <LinearProgress />
        </Box>
      </td>
    </tr>
  );

  const disbursementDeleteConfirmationModal = (
    <tr>
      <td>
        <DisbursementDeleteConfirmationModal
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={deleteDisbursement}
        />
      </td>
    </tr>
  );

  const getCategoriesInitValues = disbursementCategoryAmounts?.map((category) => ({
    id: category.id,
    amount: category.attributes?.amount?.amount,
    category: category.attributes?.category || '',
    memo: category.attributes?.notes || '',
    selected_insert: data?.relationships.smartpayablesInsert?.data?.id || '',
  }));

  const initValues = {
    check_memo_line: data?.attributes.checkMemoLine || '',
    payment_method: data?.attributes.paymentMethod,
    payee_id: selectedPayee,
    postage_code: `${data?.attributes.postageCode}`,
    card_id: cardId || '',
    categories: getCategoriesInitValues,
  };

  const balanceInfo = {
    oneMonth: clientOneMonthInfo,
    threeMonths: clientThreeMonthInfo,
    zeroDay: clientZeroDayInfo,
  };

  const balanceInfoRow = clientThreeMonthInfo ? (
    <tr className="detail-view-row">
      <td colSpan="8" style={{ padding: '0' }}>
        <DetailBalanceInfo
          balanceInfo={balanceInfo}
          currentBalance={clientThreeMonthInfo.current_balance}
          effectiveBalance={clientThreeMonthInfo.effective_balance}
          isPooled={disbursement.trust_beneficiary.is_pooled}
          organizationSlug={clientId}
        />
      </td>
    </tr>
  ) : undefined;

  const detailsView = (
    <Formik enableReinitialize initialValues={initValues} onSubmit={updateDisbursement}>
      {({ handleChange, handleSubmit, setFieldTouched, setFieldValue, values }) => (
        <>
          {detailsViewHeader(handleSubmit)}
          {balanceInfoRow}
          <DisbursementPaymentForm
            data={data}
            displayedPaymentTypes={paymentTypes}
            handleChange={handleChange}
            isEditing={isEditing}
            payees={payees}
            payeesLoaded={payeesLoaded}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            trustBeneData={trustBeneData}
            userRoles={userRoles}
            values={values}
          />
          <DisbursementCategoryAmounts
            allowInsert={disbursement.payment_method === 'Check'}
            canAddAttachments={data?.meta.canAddAttachments}
            checkInsertAttachmentId={data?.relationships.smartpayablesInsert?.data?.id}
            clientId={clientId}
            disbursementCategoryAmounts={disbursementCategoryAmounts}
            disbursementId={disbursementId}
            handleChange={handleChange}
            isEditing={isEditing}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            statusType="pending"
            values={values}
          />
          <DisbursementDecisions decisions={decisions} disbursementId={disbursementId} />
          {showDeleteModal && disbursementDeleteConfirmationModal}
        </>
      )}
    </Formik>
  );

  return <>{!isLoading ? detailsView : loading}</>;
}

PendingDisbursementsDetailView.propTypes = {
  disbursement: PropTypes.object,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
};
