import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import PrintChecksAmountCell from './PrintChecksAmountCell';
import PrintChecksButtonsCell from './PrintChecksButtonsCell';
import PrintChecksDetails from './PrintChecksDetails';
import PrintChecksPayeeAddressCell from './PrintChecksPayeeAddressCell';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default function PrintExternalChecksTable({ orgSlug, future }) {
  const [checks, setChecks] = useState([]);
  const [pageCount, setPageCount] = useState(-1);
  const [loading, setLoading] = useState(true);

  const removeChecks = (checkIds) => {
    setChecks(checks.filter((prevCheck) => !checkIds.includes(prevCheck.id)));
  };

  const columns = [
    {
      Header: 'Client',
      accessor: 'trust_beneficiary.display_name',
    },
    {
      Header: 'Trust Name',
      accessor: 'trust_beneficiary.trust.name',
    },
    {
      Header: 'Payee',
      accessor: 'payee_description',
    },
    {
      Cell: PrintChecksPayeeAddressCell,
      Header: 'Payee Address',
      accessor: 'payee',
      id: 'payee',
    },
    {
      Cell: PrintChecksAmountCell,
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Process Date',
      accessor: ({ delivery_date }) =>
        delivery_date ? moment(delivery_date).format('L') : 'On Approval',
    },
    {
      Cell: PrintChecksButtonsCell,
      id: 'external-check-buttons',
      accessor: (check) => ({ check, action: removeChecks }),
    },
  ];

  const fetchPage = useCallback(
    async (page, pageSize) => {
      setLoading(true);
      let path;
      const params = { format: 'json', page: page + 1, pageSize };
      if (future) {
        params.future = future;
      }
      if (orgSlug) {
        path = RailsRoutes.dashboard_organization_external_checks_path(orgSlug, params);
      } else {
        path = RailsRoutes.dashboard_external_checks_path(params);
      }
      const response = await axios.get(path, AXIOS_XHR_CONFIG);

      setChecks(response.data?.disbursements || []);
      setPageCount(response.data?.pages || -1);
      setLoading(false);
    },
    [orgSlug, future],
  );

  return (
    <TrueLinkTable
      columns={columns}
      controlledFetchPage={fetchPage}
      controlledPageCount={pageCount}
      data={checks}
      loading={loading}
      noDataText="There are currently no disbursements available."
      paginated
      rowSubComponent={(row) => PrintChecksDetails(row)}
      sortable={false}
    />
  );
}

PrintExternalChecksTable.propTypes = {
  orgSlug: PropTypes.string,
  future: PropTypes.bool.isRequired,
};
