import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import api from './api';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  requestButtonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputAdornment: {
    paddingLeft: '10px',
    paddingTop: '4px',
  },
}));

/**
 * @param beneficiaryId {number}
 * @param investments {number}
 * @param availableCash {number}
 * @param onClose {() => void}
 * @returns {JSX.Element}
 */
export default function RequestCashRaiseModal({
  beneficiaryId,
  investments,
  availableCash,
  onClose,
}) {
  const classes = useStyles();
  // TODO: followup on loading spinner/behavior in https://truelink.atlassian.net/browse/TRUST-3888

  const [submitting, setSubmitting] = useState(false);
  const [viewMore, setViewMore] = useState(false);

  const handleContinue = (formikValues) => {
    setSubmitting(true);
    api
      .createRaiseCashRequest(
        beneficiaryId,
        formikValues.requestCashRaiseAmount,
        formikValues.requestCashRaiseNotes,
      )
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch(() => {
        setSubmitting(false);
        window.Truelink.flash('error', 'There was an issue requesting a cash raise.');
      });
  };

  const validationSchema = Yup.object().shape({
    requestCashRaiseAmount: Yup.number()
      .typeError('Amount must be a number')
      .label('Amount')
      .required(),
    requestCashRaiseNotes: Yup.string(),
  });

  return (
    <PopUp
      header={'Request Cash Raise'}
      headerComponent={'h3'}
      hideFooter
      maxWidth="415px"
      onClose={onClose}
      openModal
      padding="10px"
    >
      <p>
        <strong>Investments:</strong> {asMoney(investments)}
      </p>
      <p>
        <strong>Available Cash:</strong> {asMoney(availableCash)}
      </p>
      <p>
        This will send an email to the trust team, they’ll get back to you with questions or
        confirmation in the next 2-3 business days.
      </p>

      <Formik
        initialErrors={{ requestCashRaiseAmount: 'required' }}
        initialValues={{
          requestCashRaiseAmount: '',
          requestCashRaiseNotes: '',
        }}
        onSubmit={handleContinue}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleSubmit, setFieldTouched, values, isValid }) => (
          <div>
            <div>Amount</div>
            <TrueLinkTextInput
              name="requestCashRaiseAmount"
              onChange={handleChange}
              required
              setFieldTouched={setFieldTouched}
              startAdornment={
                <InputAdornment className={classes.inputAdornment} position="start">
                  <TrueLinkIcon icon="dollar-sign fa-2xs" />
                </InputAdornment>
              }
              value={values.requestCashRaiseAmount}
            />
            <div>Notes</div>
            <TrueLinkTextInput
              multiline
              name="requestCashRaiseNotes"
              onChange={handleChange}
              rows={5}
              setFieldTouched={setFieldTouched}
              value={values.requestCashRaiseNotes}
            />
            {viewMore ? (
              <div>
                You are requesting additional cash in the account. Accordingly, True Link Financial
                Advisors, LLC will initiate a sale of securities from your account. This entire
                process may take up to 7 days. You understand that this will impact the investment
                allocation in the account, may subject the account to brokerage commissions and/or
                other transaction charges assessed for the sale of the securities, and may result in
                an adverse tax impact on you.
              </div>
            ) : (
              <div>
                You are requesting additional cash in the account. Accordingly, True Link Financial
                Advisors, LLC will initiate a sale of securities from your account.{' '}
                <TrueLinkLink onClick={() => setViewMore(true)}>View more</TrueLinkLink>
              </div>
            )}
            <div className={classes.requestButtonRow}>
              <TrueLinkButton
                className={classes.requestButton}
                disabled={!isValid || submitting}
                loading={submitting}
                onClick={handleSubmit}
                variant="primary"
              >
                Request
              </TrueLinkButton>
            </div>
          </div>
        )}
      </Formik>
    </PopUp>
  );
}
RequestCashRaiseModal.propTypes = {
  beneficiaryId: PropTypes.number.isRequired,
  investments: PropTypes.number,
  availableCash: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};
