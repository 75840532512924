import PropTypes from 'prop-types';
import React from 'react';
import { fetchOrg } from 'react/shared/apis/organization_api';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import { isBusinessDayAfterToday } from 'react/shared/utils/ValidBusinessDate';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardProcessDateInput extends React.Component {
  static get propTypes() {
    const { string, bool } = PropTypes;

    return {
      deliveryDate: string,
      customDateChecked: bool,
      currentPaymentMethod: string.isRequired,
      orgSlug: string.isRequired,
      cardIsOpen: bool,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      customDateChecked: !!this.props.customDateChecked,
      expectedDeliveryDate: this.formatForDatePicker(this.props.deliveryDate),
      deliveryDateError: null,
    };

    bindAll(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchOrgMetadata();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.deliveryDate !== this.props.deliveryDate ||
      prevProps.customDateChecked !== this.props.customDateChecked
    ) {
      // This is legacy behavior and would require refactoring the state structure to fix. New behavior should not thrash the render cycle this way.

      if (this._isMounted) {
        this.setState({
          customDateChecked: this.props.customDateChecked,
          expectedDeliveryDate: this.formatForDatePicker(this.props.deliveryDate),
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async fetchOrgMetadata() {
    try {
      const response = await fetchOrg(this.props.orgSlug, {
        analysisFields: 'disbursementApprovalRequired',
      });
      const disbursementApprovalRequired = response.data.data.meta.disbursementApprovalRequired;
      if (this._isMounted) {
        this.setState({ disbursementApprovalRequired });
      }
    } catch (error) {
      window.console.error(error);
    }
  }

  formatForDatePicker(date) {
    if (!date) {
      return '';
    }
    const date_vars = date.split(/\D/);
    return `${date_vars[1]}/${date_vars[2]}/${date_vars[0]}`;
  }

  toggleCustomDate(e) {
    const customDateChecked = e.target.value === 'customDate';
    if (this._isMounted) {
      this.setState({ customDateChecked });
    }
  }

  getHelperText() {
    let cardText;
    if (!this.state.customDateChecked) {
      cardText = (
        <span>
          True Link Card funds will be available <strong>immediately</strong> upon disbursement{' '}
          {this.approvalOrCreation()}
        </span>
      );
    } else {
      cardText = (
        <span>
          True Link Card funds are available on the first business day following Process Date
        </span>
      );
    }

    switch (this.props.currentPaymentMethod) {
      case 'Check':
        return <span>Checks are mailed on Process Date</span>;
      case 'Card':
        return cardText;
      case 'EFT':
        return (
          <span>
            ACH transfers are initiated on process date and take 1-4 business days to arrive
          </span>
        );
      default:
        return null;
    }
  }

  onDeliveryDateTyped(evt) {
    evt.preventDefault();
    this.onDeliveryDateSelected(evt.target.value);
  }

  onDeliveryDateSelected(dateString) {
    this.setState({
      expectedDeliveryDate: dateString,
    });
    return this.validateDeliveryDate(dateString);
  }

  validateDeliveryDate(dateString = this.state.expectedDeliveryDate) {
    const error = isBusinessDayAfterToday(dateString, 'MM/DD/YYYY')
      ? ''
      : 'Delivery Date must be a business day after today.';
    this.setState({ deliveryDateError: error });

    return this.deliveryDateValid(error);
  }

  deliveryDateValid(deliveryDateError = this.state.deliveryDateError) {
    return !deliveryDateError;
  }

  approvalOrCreation() {
    return this.state.disbursementApprovalRequired ? 'approval' : 'creation';
  }

  render() {
    return (
      <div className="new-form__data__subsection">
        <div className="new-form__label">Process Date</div>
        <div className="new-form__data">
          <div className="radio-group radio-group--inline">
            <label htmlFor="radioUponApproval">
              <input
                checked={!this.state.customDateChecked}
                disabled={this.props.currentPaymentMethod === 'Card' && !this.props.cardIsOpen}
                id="radioUponApproval"
                name="customDate"
                onChange={this.toggleCustomDate}
                type="radio"
                value="uponApproval"
              />
              Upon disbursement {this.approvalOrCreation()}{' '}
              <InfoTooltip
                placement="right"
                tooltipText={`Card instant funding is only available for activated cards. Sub-account balance will be adjusted the next business day following disbursement ${this.approvalOrCreation()}`}
              />
              {this.deliveryDateValid() ? null : <div> &nbsp; </div>}
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <input
                checked={this.state.customDateChecked}
                id="radioCustomDate"
                name="customDate"
                onChange={this.toggleCustomDate}
                type="radio"
                value="customDate"
              />
              Custom date&nbsp;
              <InfoTooltip
                placement="right"
                tooltipText="Sub-account account will be adjusted on the selected date"
              />
              &nbsp;&nbsp;
              <DatePickerField
                dateFormat="mm/dd/yy"
                handleDateSelect={this.onDeliveryDateSelected}
                inputProps={{
                  id: 'deliveryDate',
                  name: 'delivery_date',
                  defaultValue: this.state.expectedDeliveryDate,
                  disabled: !this.state.customDateChecked,
                  onChange: this.onDeliveryDateTyped,
                }}
                maxDate="+1Y"
                minDate="+1D"
              />
              {this.deliveryDateValid() ? null : (
                <div className="red"> Date invalid. Please enter a business day after today. </div>
              )}
            </label>
          </div>
        </div>
        <div className="new-form__callout clearfix" style={{ clear: 'both' }}>
          <>
            <span>
              Process Date is the date on which sub-account value is reduced by the disbursement
              amount
            </span>
            <br />
          </>
          {this.getHelperText()}
        </div>
      </div>
    );
  }
}
