import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import 'pdfjs-dist/build/pdf.worker.min.mjs';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default function PdfViewer({ url }) {
  const pdfWorkerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js';
  // eslint-disable-next-line import/namespace
  pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerSrc;

  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const scrollTop = containerRef.current.scrollTop;
      const pageHeight = containerRef.current.scrollHeight / numPages;

      const newPage = Math.min(Math.max(Math.round(scrollTop / pageHeight) + 1, 1), numPages);
      setCurrentPage(newPage);
    };

    const container = containerRef.current;
    if (container) container.addEventListener('scroll', handleScroll);

    return () => {
      if (container) container.removeEventListener('scroll', handleScroll);
    };
  }, [numPages]);

  return (
    <div className="pdf-viewer" style={{ color: 'white' }}>
      <Document file={url} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        <div ref={containerRef} style={{ height: '80vh', overflowY: 'auto' }}>
          {Array.from({ length: numPages }, (_, index) => (
            <Page key={index} pageNumber={index + 1} />
          ))}
        </div>
      </Document>
      <Box sx={{ position: 'absolute', bottom: '15px', left: '30px' }}>
        Page {currentPage} of {numPages}
      </Box>
    </div>
  );
}

PdfViewer.propTypes = {
  url: PropTypes.string.isRequired,
};
