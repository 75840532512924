import PropTypes from 'prop-types';
import React from 'react';
import AgreementAndFooter from './AgreementAndFooter';
import AlrCardOrderSection from './AlrCardOrderSection';
import BeneCardOrderSection from './BeneCardOrderSection';
import CardChoiceHeader from './CardChoiceHeader';
import EmailAndPasswordFields from 'react/member/components/signups/EmailAndPasswordFields';
import programNameShape from 'react/shared/shapes/account_program_name_shape';
import bindAll from 'react/shared/utils/bind_all';
import trackUserData from 'react/signups/card/common/MarketoTracker';

export default class AbleActProgramsCardOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      administratorAddress: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
      },
      beneficiaryAddress: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
      },
    };

    bindAll(this);
  }

  componentDidMount() {
    this.validateForm();
  }

  validateForm() {
    const trimValue = (element) => {
      $(element).val($.trim($(element).val()));
      return true;
    };

    $('#create-able-act-program-account-form').validate({
      rules: {
        'user[email]': {
          email: true,
        },
        'user[password]': {
          minlength: 8,
        },
        'user[password_confirmation]': {
          equalTo: '#user_password',
        },
        'administrator[address][street1]': {
          maxlength: 30,
        },
        'administrator[address][street2]': {
          maxlength: 30,
        },
        'administrator[address][city]': {
          maxlength: 40,
        },
        'administrator[address][zip]': {
          digits: true,
          minlength: 5,
          maxlength: 5,
        },
        'administrator[mobile]': {
          phone: true,
          northAmericanPhone: true,
        },
        'administrator[dob]': {
          validDate: true,
          nonFutureDate: true,
        },
        'administrator[first_name]': {
          required: {
            depends: trimValue,
          },
          embossingLine: true,
        },
        'administrator[last_name]': {
          required: {
            depends: trimValue,
          },
          embossingLine: true,
        },
        'beneficiary[first_name]': {
          required: {
            depends: trimValue,
          },
          embossingLine: true,
        },
        'beneficiary[last_name]': {
          required: {
            depends: trimValue,
          },
          embossingLine: true,
          fullNamesNotEqual: true,
        },
        'administrator[card_name]': {
          maxlength: 23,
          embossingLine: true,
        },
        'beneficiary[card_name]': {
          maxlength: 19,
          embossingLine: true,
        },
      },
      messages: {
        'user[password]': {
          minlength: 'Password must be at least 8 characters long',
        },
        'user[password_confirmation]': {
          equalTo: 'Passwords do not match.',
        },
        'administrator[address][street1]': {
          maxlength: 'Home address must be less than 30 characters',
        },
        'administrator[address][street2]': {
          maxlength: 'Apartment or unit number must be less than 30 characters',
        },
        'administrator[address][city]': {
          maxlength: 'City must be less than 40 characters',
        },
        'administrator[address][zip]': {
          digits: 'Zip must be 5 digits',
          minlength: 'Zip must be 5 digits',
          maxlength: 'Zip must be 5 digits',
        },
        'administrator[mobile]': {
          phone: 'Phone number must be 10 digits',
        },
        'administrator[card_name]': {
          maxlength: 'Name is too long.',
        },
        'beneficiary[card_name]': {
          maxlength: 'Name is too long.',
        },
      },
      errorPlacement(error, element) {
        error.insertAfter(element);
      },
      onfocusout(element, _event) {
        this.element(element);
      },
      onkeyup: false,
    });

    $.validator.addMethod(
      'fullNamesNotEqual',
      (_value, _element, _params) => {
        const firstName1 = $('input[name="administrator[first_name]"]').val();
        const lastName1 = $('input[name="administrator[last_name]"]').val();
        const firstName2 = $('input[name="beneficiary[first_name]"]').val();
        const lastName2 = $('input[name="beneficiary[last_name]"]').val();

        const fullName1 = `${firstName1} ${lastName1}`;
        const fullName2 = `${firstName2} ${lastName2}`;

        return fullName1 !== fullName2;
      },
      'Beneficiary name and ALR name should be different',
    );
  }

  handleAddressInputChange(evt) {
    const nameParts = evt.target.name.split('[');
    const value = evt.target.value;

    // Extract the state pieces we want to update from the split name
    // name typically looks like `administrator[address][street1]`
    const stateObject = `${nameParts[0]}Address`;
    const fieldName = nameParts.slice(-1)[0].replace(']', '');

    this.setState((prevState) => ({
      [stateObject]: {
        ...prevState[stateObject],
        [fieldName]: value,
      },
    }));
  }

  onFormSubmit(evt) {
    evt.preventDefault();
    const form = evt.target;
    if ($(form).valid()) {
      this.postData(form);
    } else {
      Truelink.flash('error', 'Please correct form errors before submitting the form.');
    }
  }

  postData(form) {
    //Get Marketo data

    const formData = new FormData(form);
    const email = formData.get('user[email]');
    const firstName = formData.get('administrator[first_name]');
    const lastName = formData.get('administrator[last_name]');
    const beneFirstName = formData.get('beneficiary[first_name]');
    const beneLastName = formData.get('beneficiary[last_name]');
    const street1 = formData.get('administrator[address][street1]');
    const street2 = formData.get('administrator[address][street2]');
    const city = formData.get('administrator[address][city]');
    const state = formData.get('administrator[address][state]');
    const zip = formData.get('administrator[address][zip]');
    const phone = formData.get('administrator[mobile]');

    trackUserData({
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Company: `${beneFirstName} ${beneLastName}`,
      Address: `${street1} ${street2}`,
      City: city,
      State: state,
      PostalCode: zip,
      Phone: phone,
    });

    $.ajax({
      url: form.action,
      type: 'POST',
      data: $(form).serialize(),
    })
      .fail((data) => {
        if (data.responseJSON && data.responseJSON.error_message) {
          Truelink.flash('error', data.responseJSON.error_message);
        } else {
          Truelink.flash('error', 'Unexpected network error.');
        }
      })
      .done((_data) => {
        window.location.href = RailsRoutes.user_home_path();
        Truelink.flash('success', 'Your new card order is now complete.');
      });
  }

  render() {
    const { isStableSite, msaLink, isAlr, alrCardChoice, programName, programNameWithArticle } =
      this.props;

    return (
      <div>
        <div className="grey_section_header hpeader" style={{ marginBottom: '20px' }}>
          <div className="grey_section_header--inner clearfix b_s_h__hed">
            {programName} Card order form
          </div>
        </div>
        <div className="widescreen-inner-container">
          <div className="questionnaire-container__able">
            <div className="questionnaire-intro-text__able">
              <CardChoiceHeader alrCardChoice={alrCardChoice} isAlr={isAlr} />
            </div>
          </div>
          <div className="card card--form">
            <div className="card--form__left">
              <div className="card--form__left__header">Create your account</div>
              <div className="card--form__left__body">
                To order {programNameWithArticle} card, please provide an email address and password
                that you'll use to log in. You can easily change this in the future if you need to.
              </div>
            </div>
            <div className="card--form__right">
              <form
                action={RailsRoutes.able_act_programs_create_account_path()}
                className="card--form__right__body"
                id="create-able-act-program-account-form"
                method="post"
                onSubmit={this.onFormSubmit}
              >
                <EmailAndPasswordFields />
                {isAlr ? (
                  <AlrCardOrderSection
                    administratorAddress={this.state.administratorAddress}
                    alrCardChoice={alrCardChoice}
                    beneficiaryAddress={this.state.beneficiaryAddress}
                    onAddressInputChange={this.handleAddressInputChange}
                    programName={programName}
                  />
                ) : (
                  <BeneCardOrderSection
                    administratorAddress={this.state.administratorAddress}
                    onAddressInputChange={this.handleAddressInputChange}
                    programName={programName}
                  />
                )}
                <AgreementAndFooter isStableSite={isStableSite} msaLink={msaLink} />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AbleActProgramsCardOrderForm.propTypes = {
  isAlr: PropTypes.bool.isRequired,
  alrCardChoice: PropTypes.string.isRequired,
  isStableSite: PropTypes.bool.isRequired,
  msaLink: PropTypes.string.isRequired,
  programName: programNameShape.isRequired,
  programNameWithArticle: PropTypes.string.isRequired,
};
