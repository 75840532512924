import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import HolidayImpactedTransfersArrivesCell from './HolidayImpactedTransfersArrivesCell';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  label: {
    paddingLeft: '10px',
  },
});

export default function HolidayImpactedTransfersCheckboxCell({ row, value }) {
  const classes = useStyles();
  const { action, canEdit, transfer } = value;
  let checked;

  if (action === 'acceptGuidance') {
    checked = row.state.guidanceAccepted === true;
  } else {
    checked = row.state.guidanceAccepted === false;
  }

  const handleCheckboxClick = () => {
    row.setState((prevState) => ({ ...prevState, guidanceAccepted: action === 'acceptGuidance' }));
  };

  return (
    <div className={classes.wrapper}>
      <input
        aria-label={`select-transfer-${transfer.id}-${action}`}
        checked={checked}
        disabled={!canEdit}
        id={`select-transfer-${transfer.id}-${action}`}
        onChange={handleCheckboxClick}
        type="checkbox"
      />
      {}
      <label className={classes.label} htmlFor={`select-transfer-${transfer.id}-${action}`}>
        <HolidayImpactedTransfersArrivesCell value={value} />
      </label>
    </div>
  );
}

HolidayImpactedTransfersCheckboxCell.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.shape({
    action: PropTypes.oneOf(['acceptGuidance', 'rejectGuidance']).isRequired,
    canEdit: PropTypes.bool.isRequired,
    transfer: impactedTransferShape.isRequired,
  }).isRequired,
};
